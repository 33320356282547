body {
  /* background: url(./assets/imgs/body-bg.png); */
  color: #333333;
}

img {max-width: 100%;}

.page-home header {
  /* margin-bottom: 330px; */
  height: 520px
}

.header-artwork {
  /* background: url(./assets/imgs/header-bg.jpg) no-repeat; */
  background-size: cover !important;
  position: absolute;
  width: 100%;
  height: 500px;
  /*z-index: -10;*/
  top: 0;
}

.header-artwork video {
  object-fit: cover;
}

.hero-container {
  position: relative;
  min-height: 450px;
}

.hero-action-buttons {
  position: absolute;
  bottom: 100px;
}

.logo-container {width: 300px}
.logo-container img {vertical-align: bottom;}

@media screen and (max-width: 767px) {
  .hero-container {
    padding: 16px !important;
  }
  .hero-image {
    margin-bottom: 25px !important;
  }
  .hero-content {
    color: #333 !important;
    width: 100%;
  }
  .hero-content .css-1cce5m9 {
    margin: 10px 0 !important;
  }
  .hero-action-buttons {
    position: static !important;
    margin-top: 20px !important;
  }
  
  .header .container {
    padding: 0 10px;
  }

  .header-searchBar .search-bar {
    margin-left: 10px !important;
  }
  .collection-contianer,
  .css-vjofe8 {
    padding-top: 10px !important;
  }
  .page-home header {
    height: 420px
  }

  .header-artwork {
    height: 400px;
    display: flex;
    align-items: flex-end;
  }

  .header-artwork video {
    max-height: 250px;
  }

  /* .page-home header {margin-bottom: 250px !important;} */

  .logo-container img {max-height: 130px;}
}

.header-toolbar {
  justify-content: space-between;
}
.header-searchBar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.header-searchBar .search-bar {
  max-width: 500px;
}

.item-image {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 30%), 0px 10px 20px rgb(0 0 0 / 30%);
  margin-bottom: 50px;
}
.item-image img, .item-image video {
  width: 100%;
  vertical-align: bottom;
}

.username-tag {
  font-weight: 600;
}

.header-icons {
  width: 100px;
  display: flex !important;
  justify-content: space-around;
}
.header-icons button {
  color: #fff !important;
}

.anchor {
  cursor: pointer;
}
