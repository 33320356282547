:root {
  /* Stops the @reach/dialog styles warning in dev */
  --reach-dialog: 1;
}

body {
  opacity: 1;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.draggable {
  cursor: move;
  z-index: 9999;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

html,
body {
  height: 100%;
}

body,
#__next {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.slider-control-bottomcenter {
  transform: translateX(0) !important;
}

@media only screen and (max-width: 52em) {
  .has-auth-header {
    padding-bottom: 96px;
  }
}

.safari .center-box {
  display: block;
}

.slider-frame {
  border-radius: 10px;
  overflow: hidden;
}

[data-reach-dialog-content] {
  outline: none;
}

[data-tippy-root] {
  z-index: 900 !important;
}

.body-fixed {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
}

@media screen {
  :root,
  .st--t-hIsMAS {
    --st--colors-black100: #000000;
    --st--colors-black95: #0d0d0d;
    --st--colors-black90: #1a1a1a;
    --st--colors-black80: #333333;
    --st--colors-black70: #4d4d4d;
    --st--colors-black60: #666666;
    --st--colors-black50: #7f7f7f;
    --st--colors-black40: #999999;
    --st--colors-black30: #b3b3b3;
    --st--colors-black20: #cccccc;
    --st--colors-black10: #e6e6e6;
    --st--colors-black5: #f2f2f2;
    --st--colors-white20: rgba(255, 255, 255, 0.2);
    --st--colors-white100: #ffffff;
    --st--colors-red100: #f93a3a;
    --st--colors-red10: #ffe9ea;
    --st--colors-green100: #24be74;
    --st--colors-green10: #e4f9ef;
    --st--colors-orange100: #f1c23e;
    --st--colors-blue100: #0101ff;
    --st--space-0: 0px;
    --st--space-1: 4px;
    --st--space-2: 8px;
    --st--space-3: 12px;
    --st--space-4: 16px;
    --st--space-5: 20px;
    --st--space-6: 24px;
    --st--space-7: 32px;
    --st--space-8: 48px;
    --st--space-9: 64px;
    --st--space-10: 96px;
    --st--space-11: 128px;
    --st--fonts-body: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    --st--fonts-mono: "FormularMono", Consolas, "Andale Mono WT", "Andale Mono",
      "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono",
      "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco,
      "Courier New", Courier, monospace;
    --st--fonts-secondary: "Roobert", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    --st--fonts-display: "Foundation Display", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    --st--fonts-roobert: "Roobert", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    --st--fontSizes-0: 14px;
    --st--fontSizes-1: 16px;
    --st--fontSizes-2: 18px;
    --st--fontSizes-3: 24px;
    --st--fontSizes-4: 36px;
    --st--fontSizes-5: 46px;
    --st--fontSizes-6: 56px;
    --st--fontSizes-7: 66px;
    --st--fontSizes-8: 76px;
    --st--fontSizes-9: 86px;
    --st--fontSizes-13: 126px;
    --st--radii-0: 0px;
    --st--radii-1: 5px;
    --st--radii-2: 10px;
    --st--radii-3: 15px;
    --st--radii-round: 9999px;
    --st--shadows-0: 0px 10px 20px rgba(0, 0, 0, 0.05);
    --st--shadows-1: 0px 10px 20px rgba(0, 0, 0, 0.1);
    --st--shadows-2: 0px 15px 20px rgba(0, 0, 0, 0.15);
    --st--shadows-3: 0px 8px 15px rgba(0, 0, 0, 0.25);
    --st--shadows-stroke: inset 0 0 0 3px #000;
    --st--shadows-button: var(--st--shadows-3);
    --st--transitions-0: 100ms;
    --st--transitions-1: 300ms;
    --st--transitions-2: 500ms;
    --st--transitions-3: 1000ms;
    --st--transitions-ease: cubic-bezier(0.23, 1, 0.32, 1);
    --st--sizes-container: 1600px;
    --st--lineHeights-body: 1.7;
  }

  .st--t-edtbld {
    --st--fonts-body: "ABCMarfa", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
  }
}

@media screen {
  @keyframes st--k-iHcQsf {
    0% {
      transform: translate3d(calc(-10% + 10vw), 0, 0);
    }

    100% {
      transform: translate3d(calc(-20% + 10vw), 0, 0);
    }
  }

  @keyframes st--k-kYJctj {
    0% {
      transform: translate(10px, 20px);
    }

    10% {
      transform: translate(-10px, 10px) rotate(-2deg);
    }

    50% {
      transform: translate(20px, 20px);
    }

    70% {
      transform: translate(-10px, 10px) rotate(-2deg);
    }

    100% {
      transform: translate(10px, 20px);
    }
  }

  @keyframes st--k-izfpre {
    0% {
      transform: translate(-10px, -20px);
    }

    10% {
      transform: translate(10px, -10px) rotate(-2deg);
    }

    50% {
      transform: translate(-20px, -20px);
    }

    70% {
      transform: translate(10px, -10px) rotate(2deg);
    }

    100% {
      transform: translate(-10px, -20px);
    }
  }

  @keyframes st--k-eYcqTb {
    0% {
      transform: translate(-10px, -20px);
    }

    10% {
      transform: translate(10px, -10px) rotate(2deg);
    }

    50% {
      transform: translate(-20px, -20px);
    }

    70% {
      transform: translate(10px, -10px) rotate(2deg);
    }

    100% {
      transform: translate(-10px, -20px);
    }
  }
}

@media screen {
  .st--c-hVuZpR {
    position: absolute;
    left: var(--st--space-5);
    top: var(--st--space-5);
  }

  .st--c-fYVAMo {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .st--c-hPhUrV {
    position: absolute;
    left: var(--st--space-5);
    bottom: var(--st--space-5);
  }

  .st--c-jmfSUC {
    position: relative;
    margin-left: var(--st--space-6);
    color: var(--st--colors-black20);
  }

  @media (hover: hover) {
    .st--c-jmfSUC:hover {
      color: var(--st--colors-black100);
    }
  }

  .st--c-jmfSUC:after {
    position: absolute;
    left: calc(var(--st--space-6) * -1);
    top: 0px;
    content: "";
    height: 24px;
    border-left: solid 1px var(--st--colors-black20);
  }

  .st--c-coUCtf {
    max-width: 580px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .st--c-bbxZDg {
    flex: 1;
    justify-content: center;
    position: relative;
  }

  @media (min-width: 52em) {
    .st--c-bbxZDg {
      padding-left: var(--st--space-7);
      padding-right: var(--st--space-7);
    }
  }

  .st--c-esvLVT {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    color: var(--st--colors-black30);
    transition: color var(--st--transitions-0) var(--st--transitions-ease);
    cursor: pointer;
  }

  .st--c-jDucxn {
    font-family: var(--st--fonts-body);
    line-height: 1;
    font-size: var(--st--fontSizes-2);
    min-height: 54px;
    display: flex;
    align-items: center;
    border-radius: var(--st--radii-round);
    -webkit-appearance: none;
    appearance: none;
    padding-left: var(--st--space-5);
    padding-right: var(--st--space-5);
    transition: var(--st--transitions-1);
    transition-property: box-shadow;
    font-weight: 600;
  }

  .st--c-jDucxn:focus {
    outline: none;
  }

  .st--c-jDucxn:disabled {
    cursor: not-allowed;
    background-color: var(--st--colors-black5);
    box-shadow: none;
    border: solid 1px var(--st--colors-black10);
  }

  .st--c-bOHzdZ {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 990;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--st--transitions-2) var(--st--transitions-ease);
    transform: translateZ(0);
  }

  .st--c-dhzjXW {
    display: flex;
  }

  .st--c-hiLUxJ {
    background-color: var(--st--colors-black10);
    height: 24px;
    width: 78px;
    border-radius: var(--st--radii-1);
  }

  .st--c-gJkQcT {
    max-width: 540px !important;
  }

  .st--c-fGHEql {
    width: 100%;
  }

  .st--c-gIvJXs {
    background-color: var(--st--colors-white100);
    box-shadow: var(--st--shadows-0);
    border-radius: var(--st--radii-round);
    padding-left: var(--st--space-5);
    padding-right: var(--st--space-5);
    padding-top: var(--st--space-3);
    padding-bottom: var(--st--space-3);
    align-items: center;
    position: relative;
    transition: box-shadow var(--st--transitions-1) var(--st--transitions-ease);
  }

  @media (hover: hover) {
    .st--c-gIvJXs:hover {
      box-shadow: var(--st--shadows-1);
    }
  }

  .st--c-ilRWiV {
    font-family: var(--st--fonts-body);
    font-size: var(--st--fontSizes-body);
    font-weight: 600;
  }

  .st--c-eISzUd {
    box-shadow: var(--st--shadows-0);
    border-radius: var(--st--radii-round);
    padding-top: var(--st--space-3);
    padding-bottom: var(--st--space-3);
    padding-left: var(--st--space-3);
    padding-right: var(--st--space-3);
    text-decoration: none;
    transition: transform var(--st--transitions-0) ease-in-out;
    will-change: transform;
    position: relative;
    z-index: 3;
    background-color: var(--st--colors-white100);
  }

  @media (hover: hover) {
    .st--c-eISzUd:hover {
      transform: translateY(-2px);
      box-shadow: var(--st--shadows-1);
    }
  }

  .st--c-eLRXHp {
    margin-left: var(--st--space-2);
    font-family: var(--st--fonts-body);
    font-weight: 600;
    font-size: var(--st--fontSizes-0);
    color: var(--st--colors-black100);
  }

  .st--c-bQzyIt {
    display: grid;
  }

  .st--c-fCBStY {
    font-family: var(--st--fonts-body);
    font-weight: 600;
    font-size: var(--st--fontSizes-1);
  }

  .st--c-kFPJGg {
    background: var(--st--colors-white100);
    font-family: var(--st--fonts-body);
    box-shadow: var(--st--shadows-0);
    border-radius: var(--st--radii-1);
    padding-top: var(--st--space-1);
    padding-bottom: var(--st--space-2);
    padding-left: var(--st--space-3);
    padding-right: var(--st--space-3);
    margin-top: var(--st--space-1);
    margin-bottom: var(--st--space-1);
    margin-left: var(--st--space-1);
    margin-right: var(--st--space-1);
    font-weight: 600;
    max-width: 270px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .st--c-gflJBE {
    cursor: pointer;
    transition: transform var(--st--transitions-1) var(--st--transitions-ease);
    will-change: transform;
  }

  @media (hover: hover) {
    .st--c-gflJBE:hover {
      transform: translateY(-2px);
    }
  }

  .st--c-bKTCSR {
    position: relative;
    z-index: 2;
    margin-left: var(--st--space-5);
    color: var(--st--colors-black50);
    cursor: pointer;
    transition: color var(--st--transitions-1) var(--st--transitions-ease);
    display: none;
  }

  @media (min-width: 52em) {
    .st--c-bKTCSR {
      display: block;
    }
  }

  @media (hover: hover) {
    .st--c-bKTCSR:hover {
      color: var(--st--colors-black100);
    }
  }

  .st--c-kpxuEd {
    box-shadow: var(--st--shadows-0);
    border-radius: var(--st--radii-round);
    min-height: 56px;
    align-items: center;
    padding-left: var(--st--space-6);
    padding-right: var(--st--space-3);
    cursor: pointer;
    transition: transform var(--st--transitions-1) var(--st--transitions-ease),
      box-shadow var(--st--transitions-1) var(--st--transitions-ease);
    will-change: transform;
  }

  @media (hover: hover) {
    .st--c-kpxuEd:hover {
      box-shadow: var(--st--shadows-1);
      transform: translateY(-2px);
    }
  }

  @media (hover: hover) {
    .st--c-kpxuEd:hover .st--c-PJLV {
      color: var(--st--colors-black100);
    }
  }

  @media (hover: hover) {
    .st--c-kpxuEd:active {
      box-shadow: var(--st--shadows-0);
      transform: translateY(0);
    }
  }

  .st--c-qTqhw {
    font-family: var(--st--fonts-body);
    font-weight: 600;
    margin-right: var(--st--space-5);
  }

  .st--c-heUYSP {
    color: var(--st--colors-black20);
    margin-right: var(--st--space-6);
    transition: color var(--st--transitions-0) var(--st--transitions-ease);
  }

  .st--c-dkxcBN {
    max-width: var(--st--sizes-container);
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    flex: 1;
    flex-grow: 1;
    padding-left: var(--st--space-6);
    padding-right: var(--st--space-6);
    width: 100%;
  }

  .st--c-eGTjFq {
    -webkit-appearance: none;
    appearance: none;
    font-family: var(--st--fonts-body);
    font-size: var(--st--fontSizes-1);
    font-weight: 600;
    cursor: pointer;
    will-change: transform;
    transition: transform var(--st--transitions-1) var(--st--transitions-ease),
      box-shadow var(--st--transitions-1) var(--st--transitions-ease),
      background-color var(--st--transitions-1) var(--st--transitions-ease),
      color var(--st--transitions-1) var(--st--transitions-ease);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    border: none;
  }

  .st--c-eGTjFq:disabled {
    pointer-events: none;
  }

  .st--c-dKbluu {
    width: 100%;
    background-color: var(--st--colors-black10);
    height: 1px;
  }

  .st--c-kqAdwj {
    width: 100%;
    height: 100%;
    background-color: var(--st--colors-black10);
  }

  .st--c-cBJbsL {
    margin-bottom: var(--st--space-6) !important;
  }

  .st--c-fGMNDP {
    border-bottom: 1px solid var(--st--colors-black10);
    margin-bottom: 20px;
  }

  @media (min-width: 52em) {
    .st--c-fGMNDP {
      margin-bottom: var(--st--space-3);
    }
  }

  @media (min-width: 64em) {
    .st--c-fGMNDP {
      margin-bottom: var(--st--space-4);
    }
  }

  .st--c-htVTRT {
    font-family: var(--st--fonts-body);
    font-weight: 600;
    font-size: var(--st--fontSizes-2);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    padding-bottom: var(--st--space-5);
    border-width: 2px;
    margin-bottom: -2px;
    margin-right: var(--st--space-6);
    transition: border-color var(--st--transitions-1)
      var(--st--transitions-ease);
    cursor: pointer;
    line-height: 1.2;
  }

  .st--c-jhsBhD {
    display: none;
    justify-content: center;
    flex-basis: 100%;
    margin-top: var(--st--space-6);
  }

  @media (min-width: 52em) {
    .st--c-jhsBhD {
      display: flex;
    }
  }

  .st--c-Iwpif {
    font-family: var(--st--fonts-body);
    font-size: var(--st--fontSizes-2);
    font-weight: 600;
    color: var(--st--colors-black60);
    text-decoration: none;
    transition: color var(--st--transitions-0) var(--st--transitions-ease);
    margin-left: var(--st--space-1);
    margin-right: var(--st--space-1);
    padding-left: var(--st--space-1);
    padding-right: var(--st--space-1);
  }

  @media (hover: hover) {
    .st--c-Iwpif:hover {
      color: var(--st--colors-black100);
    }
  }

  .st--c-Tqasi {
    align-items: center;
    position: relative;
    flex-direction: column;
  }

  @media (min-width: 52em) {
    .st--c-Tqasi {
      flex-direction: row;
    }
  }

  .st--c-Tqasi:after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #0101ff;
    transform: scaleX(1.5);
  }

  .st--c-dnnJwy {
    width: 100%;
  }

  @media (min-width: 52em) {
    .st--c-dnnJwy img {
      max-height: 890px;
      max-width: 100%;
      object-fit: contain;
    }
  }

  @media (min-width: 52em) {
    .st--c-dnnJwy {
      text-align: left;
    }
  }

  @media (min-width: 52em) {
    .st--c-dnnJwy:nth-of-type(2) {
      text-align: right;
    }
  }

  @media (max-width: 800px) {
    .st--c-dnnJwy:nth-of-type(2) {
      margin-top: -30%;
    }
  }

  @media (min-width: 800px) {
    .st--c-cBZjzK {
      margin-left: var(--st--space-7);
      margin-right: var(--st--space-7);
      padding-top: var(--st--space-7);
      padding-bottom: var(--st--space-7);
    }
  }

  .st--c-cBZjzK img {
    max-width: 300px;
  }

  @media (min-width: 800px) {
    .st--c-cBZjzK img {
      min-width: 400px;
      max-width: 800px;
    }
  }

  @media (max-width: 1200px) {
    .st--c-cBZjzK img {
      width: 100%;
    }
  }

  .st--c-hkRsSP {
    padding-top: var(--st--space-10);
    padding-bottom: var(--st--space-10);
    position: relative;
  }

  .st--c-hkRsSP:after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;
    transform: scaleX(1.5);
  }

  @media (min-width: 52em) {
    .st--c-hkRsSP {
      padding-top: 180px;
      padding-bottom: 180px;
      padding-left: var(--st--space-10);
      padding-right: var(--st--space-10);
    }
  }

  .st--c-grifje {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--st--space-9);
    line-height: 1.3;
    font-family: var(--st--fonts-body);
    font-size: var(--st--fontSizes-3);
    text-align: center;
    max-width: 960px;
  }

  @media (min-width: 52em) {
    .st--c-grifje {
      font-size: var(--st--fontSizes-4);
    }
  }

  .st--c-dShkmt {
    background-color: var(--st--colors-black80);
    color: var(--st--colors-white100);
    font-size: var(--st--fontSizes-2);
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%;
    text-align: center;
    margin-right: var(--st--space-2);
  }

  .st--c-fnHULk {
    max-width: 960px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    grid-gap: var(--st--space-8);
    margin-bottom: var(--st--space-10);
  }

  @media (min-width: 40em) {
    .st--c-fnHULk {
      margin-bottom: 180px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .st--c-cRGptI {
    font-family: var(--st--fonts-body);
    line-height: 1.3;
    letter-spacing: -0.5;
    font-size: var(--st--fontSizes-4);
    font-weight: 600;
    margin-bottom: var(--st--space-6);
  }

  @media (min-width: 52em) {
    .st--c-cRGptI {
      font-size: var(--st--fontSizes-5);
      margin-bottom: var(--st--space-9);
    }
  }

  .st--c-kSsPKS {
    font-size: 56px;
  }

  .st--c-jJhenK {
    font-family: var(--st--fonts-body);
    letter-spacing: -0.5px;
    font-size: var(--st--fontSizes-4);
    line-height: 1.3;
    font-weight: 400;
    margin-top: var(--st--space-2);
    margin-bottom: var(--st--space-3);
  }

  .st--c-fRzXQq {
    font-family: var(--st--fonts-body);
    letter-spacing: -0.5px;
    font-size: var(--st--fontSizes-2);
    line-height: 1.6;
    font-weight: 400;
  }

  .st--c-fRzXQq a {
    color: var(--st--colors-black100);
    font-family: var(--st--fonts-body);
    text-decoration: none;
    border-bottom: 1px solid var(--st--colors-black10);
    transition: color var(--st--transitions-1) var(--st--transitions-ease),
      border-bottom var(--st--transitions-ease);
  }

  @media (hover: hover) {
    .st--c-fRzXQq a:hover {
      color: var(--st--colors-blue100);
      border-bottom-color: var(--st--colors-blue100);
    }
  }

  .st--c-cVtpDF {
    margin-left: var(--st--space-5);
    list-style: disc;
  }

  .st--c-kHJQmR {
    max-width: 960px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 860px;
  }

  @media (min-width: 500px) {
    .st--c-kHJQmR {
      margin-top: 440px;
    }
  }

  @media (min-width: 2000px) {
    .st--c-kHJQmR {
      margin-top: 500px;
    }
  }

  @media (min-width: 64em) {
    .st--c-kHJQmR {
      grid-template-columns: 1fr 1fr;
    }
  }

  .st--c-hAaffR {
    margin-bottom: var(--st--space-3);
  }

  .st--c-gvnbSP {
    font-family: var(--st--fonts-body);
    letter-spacing: -0.5px;
    font-size: var(--st--fontSizes-3);
    line-height: 1.4;
    font-weight: 400;
    padding-top: var(--st--space-4);
    padding-bottom: var(--st--space-4);
    padding-left: var(--st--space-4);
    padding-right: var(--st--space-4);
    background-color: var(--st--colors-white100);
    border: 1px solid #fafafa;
    box-shadow: var(--st--shadows-0);
    border-radius: var(--st--radii-3);
    transition: transform var(--st--transitions-1) var(--st--transitions-ease),
      box-shadow var(--st--transitions-1) var(--st--transitions-ease);
    will-change: transform;
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: 52em) {
    .st--c-gvnbSP {
      font-size: var(--st--fontSizes-4);
      padding-top: var(--st--space-7);
      padding-bottom: var(--st--space-7);
      padding-left: var(--st--space-7);
      padding-right: var(--st--space-7);
    }
  }

  @media (hover: hover) {
    .st--c-gvnbSP:hover {
      transform: translateY(-4px);
      box-shadow: var(--st--shadows-1);
    }
  }

  @media (hover: hover) {
    .st--c-gvnbSP:active {
      transform: translateY(0);
      box-shadow: var(--st--shadows-0);
    }
  }

  .st--c-xlVzt {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
  }

  .st--c-fQNqSI {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(calc(10% + 10vw), 0, 0);
    animation: st--k-iHcQsf 50s linear infinite;
    animation-fill-mode: forwards;
  }

  @supports (-webkit-touch-callout: none) {
    .st--c-fQNqSI {
      -webkit-text-fill-color: var(--st--colors-blue100);
    }
  }

  .st--c-cOfaHG {
    white-space: nowrap;
    margin-left: var(--st--space-1);
    margin-right: var(--st--space-1);
  }

  @media (min-width: 52em) {
    .st--c-cOfaHG {
      margin-left: var(--st--space-5);
      margin-right: var(--st--space-5);
    }
  }

  .st--c-fjYRBI {
    font-weight: 600;
    font-size: var(--st--fontSizes-3);
    font-family: var(--st--fonts-body);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .st--c-cqjgjA {
    animation: st--k-kYJctj 25s ease-in-out infinite;
    transform-origin: center;
    width: 150px;
  }

  @media (min-width: 64em) {
    .st--c-cqjgjA {
      width: 200px;
    }
  }

  @media (min-width: 72em) {
    .st--c-cqjgjA {
      width: 400px;
    }
  }

  .st--c-cqjgjA {
    align-self: flex-end;
  }

  .st--c-bpDlhU {
    width: 360px;
  }
}

@media screen {
  .st--c-esvLVT-bubvpz-color-white {
    color: var(--st--colors-white100);
  }

  .st--c-esvLVT-bubvpz-color-white[data-active="true"] {
    color: var(--st--colors-black100);
  }

  @media (hover: hover) {
    .st--c-esvLVT-bubvpz-color-white:hover {
      color: var(--st--colors-black100);
    }
  }

  .st--c-jDucxn-gzUdsU-color-translucent {
    background-color: var(--st--colors-white20);
    color: var(--st--colors-white100);
  }

  .st--c-jDucxn-gzUdsU-color-translucent:focus,
  .st--c-jDucxn-gzUdsU-color-translucent[data-active="true"] {
    background-color: var(--st--colors-white100);
    color: var(--st--colors-black100);
  }

  .st--c-jDucxn-coXQHn-size-large {
    --st---shadowColor: rgba(0, 0, 0, 0.05);
    --st---strokeColor: transparent;
    --st---borderColor: var(--st--colors-black100);
    --st---placeholderColor: var(--st--colors-black40);
    --st---placeholderFocusColor: var(--st--colors-black40);
    border: none;
    box-shadow: 0px 0px 0px 1px var(--st---strokeColor),
      0px 10px 20px var(--st---shadowColor);
  }

  .st--c-jDucxn-coXQHn-size-large::placeholder {
    color: var(--st---placeholderColor);
  }

  .st--c-jDucxn-coXQHn-size-large:focus,
  .st--c-jDucxn-coXQHn-size-large[data-active="true"] {
    box-shadow: inset 0px 0px 0px 3px var(--st---borderColor),
      0px 10px 20px var(--st---shadowColor);
  }

  .st--c-jDucxn-coXQHn-size-large:focus::placeholder,
  .st--c-jDucxn-coXQHn-size-large[data-active="true"]::placeholder {
    color: var(--st---placeholderFocusColor);
  }

  .st--c-esvLVT-eZRHIp-color-black {
    color: var(--st--colors-black30);
  }

  .st--c-esvLVT-eZRHIp-color-black[data-active="true"] {
    color: var(--st--colors-black100);
  }

  @media (hover: hover) {
    .st--c-esvLVT-eZRHIp-color-black:hover {
      color: var(--st--colors-black100);
    }
  }

  .st--c-jDucxn-iHftov-color-white {
    background-color: var(--st--colors-white100);
    color: var(--st--colors-black100);
    border: solid 1px var(--st--colors-black10);
  }

  .st--c-eGTjFq-cFQsmr-color-black {
    background-color: var(--st--colors-black100);
    color: var(--st--colors-white100);
  }

  .st--c-eGTjFq-cmLWqr-size-large {
    min-height: 60px;
    padding-left: var(--st--space-5);
    padding-right: var(--st--space-5);
  }

  .st--c-eGTjFq-flTkwI-shape-regular {
    border-radius: var(--st--radii-3);
  }

  .st--c-htVTRT-fOvarM-isActive-true {
    border-color: var(--st--colors-black100);
  }

  .st--c-Iwpif-lpusRC-isDark-true {
    color: var(--st--colors-white100);
  }

  @media (hover: hover) {
    .st--c-Iwpif-lpusRC-isDark-true:hover {
      color: var(--st--colors-black30);
    }
  }

  .st--c-hkRsSP-gFDgJQ-color-green:after {
    background: linear-gradient(180deg, #bdff00 0%, rgba(189, 255, 0, 0) 100%);
  }

  .st--c-eGTjFq-hSNUmf-size-regular {
    min-height: 46px;
    padding-left: var(--st--space-2);
    padding-right: var(--st--space-2);
  }

  .st--c-eGTjFq-rloUt-shape-round {
    border-radius: var(--st--radii-round);
  }

  @media (hover: hover) {
    .st--c-eGTjFq-jAWrS-hoverable-true:hover {
      transform: translateY(-2px);
      box-shadow: var(--st--shadows-1);
    }
  }

  @media (hover: hover) {
    .st--c-eGTjFq-jAWrS-hoverable-true:active {
      transform: translateY(0);
      box-shadow: var(--st--shadows-0);
    }
  }

  .st--c-hkRsSP-iOONdT-color-yellow:after {
    height: 140%;
    background: linear-gradient(180deg, #faff00 0%, rgba(250, 255, 0, 0) 100%);
  }

  .st--c-dShkmt-ihZVQI-size-title {
    background: var(--st--colors-blue100);
  }

  @media (min-width: 52em) {
    .st--c-dShkmt-ihZVQI-size-title {
      width: 52px;
      height: 52px;
      font-size: var(--st--fontSizes-4);
      line-height: 53px;
      margin-right: var(--st--space-5);
    }
  }

  .st--c-eGTjFq-hlrGHw-color-blue {
    background-color: var(--st--colors-blue100);
    color: var(--st--colors-white100);
  }

  @media (hover: hover) {
    .st--c-eGTjFq-hlrGHw-color-blue:hover {
      background-color: var(--st--colors-black100);
    }
  }

  .st--c-Iwpif-eMHCPc-isActive-true {
    color: var(--st--colors-black100);
  }
}

@media screen {
  .st--c-jDucxn-eqTFaV-cv {
    --st---placeholderColor: var(--st--colors-white100);
  }

  .st--c-jDucxn-gHBZYO-cv {
    --st---strokeColor: rgba(0, 0, 0, 0.05);
  }
}

@media screen {
  .st--c-PJLV-ihMHlQk-css {
    display: none;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ihMHlQk-css {
      display: block;
    }
  }

  .creator-flow .st--c-PJLV-ihMHlQk-css {
    display: none;
  }

  .st--c-PJLV-ihLryUN-css {
    position: relative;
    z-index: 999;
  }

  .st--c-PJLV-ifaEBXu-css {
    left: 20px;
    pointer-events: none;
  }

  .st--c-jDucxn-igIWDdp-css {
    border: none;
    width: 100%;
    padding-left: var(--st--space-8);
  }

  .st--c-PJLV-icmpvrW-css {
    position: relative;
  }

  .st--c-PJLV-iiCBCau-css {
    opacity: 0;
    pointer-events: none;
    display: none;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-iiCBCau-css {
      display: block;
    }
  }

  .st--c-PJLV-iiRAzIS-css {
    height: 42px;
    margin-bottom: var(--st--space-4);
    border-bottom: solid 1px var(--st--colors-black10);
  }

  .st--c-PJLV-ijvCGUR-css {
    margin-right: var(--st--space-6);
    height: 22px;
    width: 96px;
  }

  .st--c-PJLV-ibuLmHf-css {
    height: 22px;
    width: 96px;
  }

  .st--c-PJLV-ibFrTap-css {
    padding-top: var(--st--space-8);
  }

  .st--c-PJLV-iincitF-css {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .st--c-PJLV-ijdxzuS-css {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
  }

  .st--c-PJLV-ichNmfm-css {
    margin-right: var(--st--space-3);
  }

  .st--c-PJLV-ikYMMba-css {
    position: relative;
    z-index: 2;
  }

  .st--c-PJLV-icHZnbS-css {
    display: inline-flex;
  }

  .st--c-PJLV-ieKWVTQ-css {
    gap: var(--st--space-5);
  }

  .st--c-PJLV-iiEUwSm-css {
    flex-wrap: wrap;
    max-width: 350px;
  }

  .st--c-PJLV-idCpVsf-css {
    gap: var(--st--space-6);
  }

  .st--c-PJLV-iepxevy-css {
    flex-direction: row-reverse;
    padding-right: var(--st--space-3);
  }

  .st--c-PJLV-icCpJUv-css {
    margin-right: calc(var(--st--space-3) * -1);
  }

  .st--c-PJLV-igvSFcp-css {
    background-color: var(--st--colors-black100);
    color: var(--st--colors-black60);
    padding-top: var(--st--space-5);
    padding-bottom: var(--st--space-5);
    z-index: 1;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-igvSFcp-css {
      padding-top: var(--st--space-8);
      padding-bottom: var(--st--space-8);
    }
  }

  .st--c-PJLV-ikIkOUN-css {
    padding-bottom: var(--st--space-5);
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ikIkOUN-css {
      padding-bottom: var(--st--space-9);
      flex-direction: row;
    }
  }

  .st--c-PJLV-ieglQRW-css {
    max-width: 290px;
    display: none;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ieglQRW-css {
      display: block;
    }
  }

  .st--c-PJLV-icIKSC-css {
    font-family: var(--st--fonts-body);
    color: var(--st--colors-white100);
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: var(--st--fontSizes-3);
    margin-bottom: var(--st--space-2);
  }

  .st--c-PJLV-igWVNyz-css {
    font-family: var(--st--fonts-body);
    color: var(--st--colors-black60);
    font-weight: 600;
    font-size: var(--st--fontSizes-1);
    line-height: 1.375;
  }

  .st--c-eGTjFq-iiULJkv-css {
    border: 1px solid var(--st--colors-black80);
    margin-top: var(--st--space-7);
  }

  @media (hover: hover) {
    .st--c-eGTjFq-iiULJkv-css:hover {
      background-color: var(--st--colors-blue100);
      border-color: var(--st--colors-blue100);
    }
  }

  .st--c-PJLV-ijbmWTM-css {
    flex-direction: column;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ijbmWTM-css {
      text-align: right;
      align-items: flex-end;
    }
  }

  .st--c-PJLV-ijbmWTM-css a {
    font-size: var(--st--fontSizes-3);
    line-height: 1.4;
  }

  .st--c-PJLV-icCeaCE-css {
    display: block;
    font-size: 14px;
    color: var(--st--colors-black60);
    font-family: var(--st--fonts-body);
    font-weight: 600;
    text-decoration: none;
    transition: color var(--st--transitions-2) var(--st--transitions-ease);
  }

  @media (hover: hover) {
    .st--c-PJLV-icCeaCE-css:hover {
      color: var(--st--colors-white100);
    }
  }

  .st--c-PJLV-ijjBYj-css {
    background-color: var(--st--colors-black80);
  }

  .st--c-PJLV-iemxmem-css {
    padding-top: var(--st--space-5);
    flex-direction: column;
    justify-content: space-between;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-iemxmem-css {
      padding-top: var(--st--space-7);
      flex-direction: row;
    }
  }

  .st--c-PJLV-ijnMsau-css {
    flex-direction: column;
    margin-bottom: var(--st--space-6);
  }

  .st--c-PJLV-ijnMsau-css a {
    padding-bottom: var(--st--space-2);
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ijnMsau-css {
      flex-direction: row;
      margin-bottom: unset;
    }
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ijnMsau-css a {
      padding-right: var(--st--space-6);
      padding-bottom: 0;
    }
  }

  .st--c-PJLV-idRBfgk-css {
    margin-left: 0;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-idRBfgk-css {
      margin-left: var(--st--space-6);
    }
  }

  .st--c-PJLV-icJzJCU-css {
    display: none;
  }

  @media (min-width: 80em) {
    .st--c-PJLV-icJzJCU-css {
      display: block;
      position: absolute;
      left: 50%;
      bottom: 20px;
      width: 16px;
      height: 22px;
      transform: translateX(-50%);
    }
  }

  .st--c-eGTjFq-idbrRtx-css {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--st--space-4);
    padding-right: var(--st--space-4);
    font-family: var(--st--fonts-roobert);
  }

  .st--c-PJLV-iinzmIz-css {
    grid-row-gap: var(--st--space-8);
  }

  .st--c-PJLV-icEEiNx-css {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
  }

  @media (max-width: 500px) {
    .st--c-PJLV-icEEiNx-css {
      max-height: 700px;
    }
  }

  .st--c-PJLV-iivYAPe-css {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .st--c-PJLV-ieRubBV-css {
    margin-top: 160px;
  }

  .st--c-cRGptI-ijIzQPJ-css {
    display: flex;
    align-items: center;
    margin-bottom: var(--st--space-6);
  }

  .st--c-PJLV-ifAiGR-css {
    grid-row-gap: 10px;
    max-width: 912px;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--st--space-9);
  }

  .st--c-fRzXQq-iglOyyo-css {
    margin-top: var(--st--space-7);
    margin-bottom: var(--st--space-7);
  }

  @media (min-width: 52em) {
    .st--c-fRzXQq-iglOyyo-css {
      margin-top: var(--st--space-10);
      margin-bottom: var(--st--space-9);
    }
  }

  .st--c-PJLV-ishxxE-css {
    background-color: #05ff00;
    position: relative;
    height: 148px;
    left: 0;
    right: 0;
    box-shadow: inset 0px 4px 22px rgba(0, 0, 0, 0.25);
    display: none;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ishxxE-css {
      display: block;
    }
  }

  .st--c-xlVzt-ikfKmvx-css {
    top: 50%;
    transform: translateY(-50%);
  }

  .st--c-PJLV-iqTNbO-css {
    max-width: unset;
    width: 180vw;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-iqTNbO-css {
      width: unset;
    }
  }

  .st--c-PJLV-ihQWPT-css {
    background-color: #05ff00;
    padding-top: var(--st--space-8);
    padding-bottom: var(--st--space-8);
    box-shadow: inset 0px 4px 22px rgba(0, 0, 0, 0.25);
    display: block;
    text-align: center;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ihQWPT-css {
      display: none;
    }
  }

  .st--c-PJLV-ifjeosf-css {
    max-height: 228px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .st--c-PJLV-ifSzHCO-css {
    padding-top: var(--st--space-9);
    padding-bottom: var(--st--space-9);
    background: var(--st--colors-black95);
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ifSzHCO-css {
      padding-top: 152px;
      padding-bottom: 152px;
      overflow-x: clip;
    }
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ifPvwZm-css {
      position: relative;
      z-index: 1;
    }
  }

  .st--c-PJLV-ieGAUUG-css {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    grid-row-gap: var(--st--space-6);
    text-align: center;
  }

  @media (min-width: 52em) {
    .st--c-PJLV-ieGAUUG-css {
      grid-row-gap: var(--st--space-8);
    }
  }

  .st--c-PJLV-ilhdIMX-css {
    display: none;
  }

  @media (min-width: 72em) {
    .st--c-PJLV-ilhdIMX-css {
      display: flex;
      position: absolute;
      left: -2%;
      top: -50%;
      bottom: -17%;
      z-index: -1;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .st--c-PJLV-icWJUwG-css {
    font-family: var(--st--fonts-body);
    color: var(--st--colors-black40);
    letter-spacing: -0.2px;
    font-size: var(--st--fontSizes-3);
    text-align: center;
    line-height: 1.3;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }

  .st--c-eGTjFq-iiutDgx-css {
    width: 230px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--st--fontSizes-2);
  }

  .st--c-PJLV-ibpwILV-css {
    display: none;
  }

  @media (min-width: 72em) {
    .st--c-PJLV-ibpwILV-css {
      display: flex;
      position: absolute;
      right: -2%;
      top: -50%;
      bottom: -25%;
      z-index: -1;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}

.css-68ykrv {
  position: absolute;
  left: 0;
  z-index: 999;
  width: 100%;
}

.css-nqufkc {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: absolute;
  left: 0;
  z-index: 999;
  width: 100%;
}

.css-1rkosg9 {
  padding-top: 24px;
}

@media screen and (min-width: 40em) {
  .css-1rkosg9 {
    padding-top: 32px;
  }
}

.css-12zk7sw {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
}

@media screen and (min-width: 40em) {
  .css-12zk7sw {
    padding-top: 32px;
  }
}

.css-sm3nie {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
}

@media screen and (min-width: 40em) {
  .css-sm3nie {
    padding-top: 32px;
  }
}

.css-m9dcjj {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.css-152yy2d {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.css-valc8c {
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

@media screen and (min-width: 52em) {
  .css-valc8c {
    margin-bottom: 0;
  }
}

.css-15priia {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

@media screen and (min-width: 52em) {
  .css-15priia {
    margin-bottom: 0;
  }
}

.css-7rqqtz {
  color: var(--theme-ui-colors-black-100, #000000);
}

.css-1ruquek {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: var(--theme-ui-colors-black-100, #000000);
}

.css-1qdiezr {
  color: var(--theme-ui-colors-black-100, #000000);
  display: block;
}

.css-2tv2tn {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: var(--theme-ui-colors-black-100, #000000);
  display: block;
}

.css-13o7eu2 {
  display: block;
}

.css-3zn2ct {
  padding-bottom: 33.6734694%;
  height: 0;
  width: 75px;
}

@media screen and (min-width: 40em) {
  .css-3zn2ct {
    width: 98px;
  }
}

.css-crfjb9 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  padding-bottom: 33.6734694%;
  height: 0;
  width: 75px;
}

@media screen and (min-width: 40em) {
  .css-crfjb9 {
    width: 98px;
  }
}

.css-ahfaul {
  padding: 12px;
  background-color: var(--theme-ui-colors-white-100, #ffffff);
  color: var(--theme-ui-colors-black-100, #000000);
  border-radius: 999px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.css-p1va4u {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  padding: 12px;
  background-color: var(--theme-ui-colors-white-100, #ffffff);
  color: var(--theme-ui-colors-black-100, #000000);
  border-radius: 999px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.css-1ra2eh0 {
  width: 30px;
  -webkit-animate-fill-mode: forwards;
  animate-fill-mode: forwards;
  -webkit-animation: animation-p6rlcy 900ms linear infinite;
  animation: animation-p6rlcy 900ms linear infinite;
}

@-webkit-keyframes animation-p6rlcy {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-p6rlcy {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.css-qqpvm6 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 30px;
  -webkit-animate-fill-mode: forwards;
  animate-fill-mode: forwards;
  -webkit-animation: animation-p6rlcy 900ms linear infinite;
  animation: animation-p6rlcy 900ms linear infinite;
}

.css-10j62sq {
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  height: none !important;
}

@media screen and (min-width: 40em) {
  .css-10j62sq {
    height: none !important;
  }
}

@media screen and (min-width: 52em) {
  .css-10j62sq {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: calc(100vh - 168px);
  }
}

.css-1d0v6jz {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  height: none !important;
}

@media screen and (min-width: 40em) {
  .css-1d0v6jz {
    height: none !important;
  }
}

@media screen and (min-width: 52em) {
  .css-1d0v6jz {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: calc(100vh - 168px);
  }
}

.css-1ycdr52 {
  background-color: #f2f2f2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 66vh;
}

@media screen and (min-width: 52em) {
  .css-1ycdr52 {
    min-height: 0;
  }
}

.css-1ycdr52:before {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: linear-gradient(
    0deg,
    rgba(230, 230, 230, 1) 0%,
    rgba(230, 230, 230, 0) 100%
  );
}

.css-15tbml8 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: #f2f2f2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 66vh;
}

@media screen and (min-width: 52em) {
  .css-15tbml8 {
    min-height: 0;
  }
}

.css-15tbml8:before {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: linear-gradient(
    0deg,
    rgba(230, 230, 230, 1) 0%,
    rgba(230, 230, 230, 0) 100%
  );
}

.css-ib0666 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 66vh;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 96px;
  margin-bottom: 48px;
}

@media screen and (min-width: 52em) {
  .css-ib0666 {
    min-height: 0;
    margin-left: calc(8rem + calc(14vmin - 8rem));
    margin-right: calc(8rem + calc(14vmin - 8rem));
    margin-top: calc(8rem + calc(14vmin - 8rem));
    margin-bottom: calc(8rem + calc(14vmin - 8rem));
  }
}

.css-uny3or {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 66vh;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 96px;
  margin-bottom: 48px;
}

@media screen and (min-width: 52em) {
  .css-uny3or {
    min-height: 0;
    margin-left: calc(8rem + calc(14vmin - 8rem));
    margin-right: calc(8rem + calc(14vmin - 8rem));
    margin-top: calc(8rem + calc(14vmin - 8rem));
    margin-bottom: calc(8rem + calc(14vmin - 8rem));
  }
}

.css-ldo4d5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.css-55ahcf {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.css-kjafn5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  justify-content: center;
}

.css-kjafn5 img {
  max-width: 100%;
  object-fit: contain;
}

.css-uavj3a {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
  -webkit-filter: drop-shadow(0 5px 8px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 5px 8px rgba(0, 0, 0, 0.25));
}

@media screen and (min-width: 40em) {
  .css-uavj3a {
    -webkit-filter: drop-shadow(0 20px 20px rgba(0, 0, 0, 0.25)) blur(10px);
    filter: drop-shadow(0 20px 20px rgba(0, 0, 0, 0.25)) blur(10px);
  }
}

.css-n4evfj {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--theme-ui-colors-white-100, #ffffff);
}

.css-1f3hm4n {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--theme-ui-colors-white-100, #ffffff);
}

.css-1e5vb46 {
  width: 32px;
  -webkit-animate-fill-mode: forwards;
  animate-fill-mode: forwards;
  -webkit-animation: animation-p6rlcy 900ms linear infinite;
  animation: animation-p6rlcy 900ms linear infinite;
}

@-webkit-keyframes animation-p6rlcy {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-p6rlcy {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.css-1d71nh {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 32px;
  -webkit-animate-fill-mode: forwards;
  animate-fill-mode: forwards;
  -webkit-animation: animation-p6rlcy 900ms linear infinite;
  animation: animation-p6rlcy 900ms linear infinite;
}

.css-mv3jfh {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 24px;
  padding-bottom: 48px;
}

.css-359uwn {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 24px;
  padding-bottom: 48px;
}

.css-1isemmb {
  margin-left: 8px;
}

.css-d6n0rs {
  -webkit-transform: translateY(-1.5rem);
  -ms-transform: translateY(-1.5rem);
  transform: translateY(-1.5rem);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  margin-bottom: -1.5rem;
  position: relative;
  z-index: 4;
}

@media screen and (min-width: 52em) {
  .css-d6n0rs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 64em) {
  .css-d6n0rs {
    gap: 48px;
  }
}

.css-ryzssb {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-transform: translateY(-1.5rem);
  -ms-transform: translateY(-1.5rem);
  transform: translateY(-1.5rem);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  margin-bottom: -1.5rem;
  position: relative;
  z-index: 4;
}

@media screen and (min-width: 52em) {
  .css-ryzssb {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 64em) {
  .css-ryzssb {
    gap: 48px;
  }
}

.css-18bid03 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-transform: translateY(-1.5rem);
  -ms-transform: translateY(-1.5rem);
  transform: translateY(-1.5rem);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  margin-bottom: -1.5rem;
  position: relative;
  z-index: 4;
}

@media screen and (min-width: 52em) {
  .css-18bid03 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 64em) {
  .css-18bid03 {
    gap: 48px;
  }
}

.css-4cffwv {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}

.css-4g6ai3 {
  cursor: pointer;
}

.css-1jm49l2 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  cursor: pointer;
}

.css-13fkovv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-text-decoration: none;
  text-decoration: none;
  pointer-events: none;
}

.css-1rq9w7v {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-text-decoration: none;
  text-decoration: none;
  pointer-events: none;
}

.css-1xhjjlw {
  padding: 8px;
  background: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9999px;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
  padding-right: 20px;
}

@media screen and (min-width: 40em) {
  .css-1xhjjlw {
    padding: 11px;
    padding-right: 20px;
  }
}

@media (hover: hover) {
  .css-1xhjjlw:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  .css-1xhjjlw:active {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.css-nl5svz {
  background: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 8px;
  background: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9999px;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
  padding-right: 20px;
}

@media screen and (min-width: 40em) {
  .css-nl5svz {
    padding: 11px;
    padding-right: 20px;
  }
}

@media (hover: hover) {
  .css-nl5svz:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  .css-nl5svz:active {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.css-1fp3cxy {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 8px;
  background: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9999px;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
  padding-right: 20px;
}

@media screen and (min-width: 40em) {
  .css-1fp3cxy {
    padding: 11px;
    padding-right: 20px;
  }
}

@media (hover: hover) {
  .css-1fp3cxy:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  .css-1fp3cxy:active {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.css-ttdgk8 {
  background-color: var(--theme-ui-colors-black-10, #e6e6e6);
  width: 24px;
  height: 24px;
  border-radius: 999px;
}

@media screen and (min-width: 40em) {
  .css-ttdgk8 {
    width: 34px;
    height: 34px;
  }
}

.css-14yg4v8 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: var(--theme-ui-colors-black-10, #e6e6e6);
  width: 24px;
  height: 24px;
  border-radius: 999px;
}

@media screen and (min-width: 40em) {
  .css-14yg4v8 {
    width: 34px;
    height: 34px;
  }
}

.css-k008qs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.css-bqlz6x {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--theme-ui-colors-black-100, #000000);
  position: relative;
  top: -1px;
  font-size: 14px;
  background-color: var(--theme-ui-colors-black-5, #f2f2f2);
  width: 120px;
  height: 20px;
}

@media screen and (min-width: 40em) {
  .css-bqlz6x {
    font-size: 16px;
  }
}

.css-1932dle {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: 8px;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--theme-ui-colors-black-100, #000000);
  position: relative;
  top: -1px;
  font-size: 14px;
  background-color: var(--theme-ui-colors-black-5, #f2f2f2);
  width: 120px;
  height: 20px;
}

@media screen and (min-width: 40em) {
  .css-1932dle {
    font-size: 16px;
  }
}

.css-ib8zlr {
  margin-left: auto;
  pointer-events: none;
}

.css-1ncgsqv {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  pointer-events: none;
}

.css-yh73ju .popover-button {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 40em) {
  .css-yh73ju .popover-button {
    width: 56px;
    height: 56px;
  }
}

@media (hover: hover) {
  .css-yh73ju .popover-button:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .css-yh73ju .popover-button:active {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
}

.css-9wzxin {
  outline: none;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  will-change: transform;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  margin-right: 24px;
}

@media (hover: hover) {
  .css-9wzxin:hover {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  .css-9wzxin:active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.css-9wzxin .popover-button {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 40em) {
  .css-9wzxin .popover-button {
    width: 56px;
    height: 56px;
  }
}

@media (hover: hover) {
  .css-9wzxin .popover-button:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .css-9wzxin .popover-button:active {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
}

.css-1xnwqql {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  outline: none;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  will-change: transform;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  margin-right: 24px;
}

@media (hover: hover) {
  .css-1xnwqql:hover {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  .css-1xnwqql:active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.css-1xnwqql .popover-button {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 40em) {
  .css-1xnwqql .popover-button {
    width: 56px;
    height: 56px;
  }
}

@media (hover: hover) {
  .css-1xnwqql .popover-button:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .css-1xnwqql .popover-button:active {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
}

.css-1q1gxuo {
  background-color: #fff !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
}

.css-1k3clgp {
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  cursor: pointer;
  background-color: #fff !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
}

@media (hover: hover) {
  .css-1k3clgp:hover {
    background-color: var(--theme-ui-colors-black-5, #f2f2f2);
  }

  .css-1k3clgp:active {
    -webkit-transform: black.10;
    -ms-transform: black.10;
    transform: black.10;
  }
}

.css-1gp26yl {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  cursor: pointer;
  background-color: #fff !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
}

@media (hover: hover) {
  .css-1gp26yl:hover {
    background-color: var(--theme-ui-colors-black-5, #f2f2f2);
  }

  .css-1gp26yl:active {
    -webkit-transform: black.10;
    -ms-transform: black.10;
    transform: black.10;
  }
}

.css-113x2r9 {
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

@media screen and (min-width: 40em) {
  .css-113x2r9 {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.css-1ehn3we {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

@media screen and (min-width: 40em) {
  .css-1ehn3we {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.css-13o7eu2 {
  display: block;
}

.css-b4jdui {
  outline: none;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  will-change: transform;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.css-b4jdui[aria-expanded="true"] {
  -webkit-transform: translateY(0) !important;
  -ms-transform: translateY(0) !important;
  transform: translateY(0) !important;
  box-shadow: none !important;
}

@media (hover: hover) {
  .css-b4jdui:hover {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .css-b4jdui:active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.css-l90koa {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  outline: none;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  will-change: transform;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.css-l90koa[aria-expanded="true"] {
  -webkit-transform: translateY(0) !important;
  -ms-transform: translateY(0) !important;
  transform: translateY(0) !important;
  box-shadow: none !important;
}

@media (hover: hover) {
  .css-l90koa:hover {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .css-l90koa:active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.css-xeguv0 {
  width: auto;
  background-color: #fff !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 40em) {
  .css-xeguv0 {
    min-height: 56px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.css-lsl1er {
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  cursor: pointer;
  width: auto;
  background-color: #fff !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

@media (hover: hover) {
  .css-lsl1er:hover {
    background-color: var(--theme-ui-colors-black-5, #f2f2f2);
  }

  .css-lsl1er:active {
    -webkit-transform: black.10;
    -ms-transform: black.10;
    transform: black.10;
  }
}

@media screen and (min-width: 40em) {
  .css-lsl1er {
    min-height: 56px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.css-3ts36d {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 999px;
  cursor: pointer;
  width: auto;
  background-color: #fff !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

@media (hover: hover) {
  .css-3ts36d:hover {
    background-color: var(--theme-ui-colors-black-5, #f2f2f2);
  }

  .css-3ts36d:active {
    -webkit-transform: black.10;
    -ms-transform: black.10;
    transform: black.10;
  }
}

@media screen and (min-width: 40em) {
  .css-3ts36d {
    min-height: 56px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.css-h5gews {
  margin-left: 10px;
  display: none;
  position: relative;
  top: -2px;
}

@media screen and (min-width: 40em) {
  .css-h5gews {
    display: block;
  }
}

.css-yppw5a {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  display: none;
  position: relative;
  top: -2px;
}

@media screen and (min-width: 40em) {
  .css-yppw5a {
    display: block;
  }
}

.css-1igkdxz {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.css-vjofe8 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 50px;
}

.css-1s0zqj2 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 52em) {
  .css-1s0zqj2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 64em) {
  .css-1s0zqj2 {
    grid-gap: 48px;
  }
}

.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}

.css-ztn6jp {
  background-color: var(--theme-ui-colors-white-100, #ffffff);
  position: relative;
  z-index: 2;
  padding-bottom: 16px;
}

@media screen and (min-width: 40em) {
  .css-ztn6jp {
    padding-bottom: 32px;
  }
}

.css-8zdpe8 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
  z-index: 2;
  padding-bottom: 16px;
}

@media screen and (min-width: 40em) {
  .css-8zdpe8 {
    padding-bottom: 32px;
  }
}

.css-o1wrfk {
  display: block;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

@media screen and (min-width: 64em) {
  .css-o1wrfk {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.css-pk1a08 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: block;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

@media screen and (min-width: 64em) {
  .css-pk1a08 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.css-ieri5n {
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.css-f19vuz {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.css-1xaccwf {
  margin-top: 24px;
  margin-bottom: 24px;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

@media screen and (min-width: 64em) {
  .css-1xaccwf {
    margin-bottom: 0;
  }
}

.css-1cce5m9 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: heading;
  font-weight: 600;
  line-height: heading;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 36px;
  line-height: 1.15;
  -webkit-letter-spacing: -0.02em;
  -moz-letter-spacing: -0.02em;
  -ms-letter-spacing: -0.02em;
  letter-spacing: -0.02em;
  font-weight: 600;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-top: 20px
}

@media screen and (min-width: 52em) {
  /* .css-1cce5m9 {
    font-size: 46px;
  } */
}

@media screen and (min-width: 64em) {
  .css-1cce5m9 {
    margin-bottom: 0;
  }
}

.css-14fmp3e {
  margin-top: 8px;
  display: none;
}

@media screen and (min-width: 64em) {
  .css-14fmp3e {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.css-16n8vql {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-top: 8px;
  display: none;
}

@media screen and (min-width: 64em) {
  .css-16n8vql {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.css-curjna {
  cursor: pointer;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--theme-ui-colors-black-30, #b3b3b3);
}

@media (hover: hover) {
  .css-curjna {
    -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .css-curjna:hover {
    color: var(--theme-ui-colors-black-100, #000000);
  }
}

.css-1mj918r {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--theme-ui-colors-black-30, #b3b3b3);
}

@media (hover: hover) {
  .css-1mj918r {
    -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .css-1mj918r:hover {
    color: var(--theme-ui-colors-black-100, #000000);
  }
}

.css-cs1vz7 {
  font-weight: bold;
  margin-left: 8px;
}

.css-13s8ujt {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  font-weight: bold;
  margin-left: 8px;
}

.css-1g4f2k1 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 32px;
}

@media screen and (min-width: 52em) {
  .css-1g4f2k1 {
    grid-gap: 48px;
  }
}

.css-19idom {
  margin-bottom: 8px;
}

.css-ofwg41 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #CCC
}

.css-7qe73w {
  max-width: 27rem;
}

.css-7qe73w > p:not(:last-of-type) {
  margin-bottom: 16px;
}

.css-7qe73w > ol {
  list-style-position: inside;
}

.css-7qe73w pre {
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  white-space: normal;
}

.css-7qe73w code {
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  white-space: normal;
}

.css-5jvy1z {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  max-width: 27rem;
}

.css-5jvy1z > p:not(:last-of-type) {
  margin-bottom: 16px;
}

.css-5jvy1z > ol {
  list-style-position: inside;
}

.css-5jvy1z pre {
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  white-space: normal;
}

.css-5jvy1z code {
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  white-space: normal;
}

.css-1ruklw {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 36px;
  line-height: 1.15;
  -webkit-letter-spacing: -0.02em;
  -moz-letter-spacing: -0.02em;
  -ms-letter-spacing: -0.02em;
  letter-spacing: -0.02em;
  font-weight: 600;
  max-width: 27rem;
}

@media screen and (min-width: 52em) {
  .css-1ruklw {
    font-size: 46px;
  }
}

.css-1ruklw > p:not(:last-of-type) {
  margin-bottom: 16px;
}

.css-1ruklw > ol {
  list-style-position: inside;
}

.css-1ruklw pre {
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  white-space: normal;
}

.css-1ruklw code {
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  white-space: normal;
}

.css-16r9pk3 {
  max-width: 400px;
}

.css-1hhedd7 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 8px;
  max-width: 400px;
}

.css-r6z5ec {
  z-index: 1;
}

.css-1l86mjk {
  background-color: var(--theme-ui-colors-white-100, #ffffff);
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform, boxShadow;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--theme-ui-colors-black-30, #b3b3b3);
  cursor: pointer;
  z-index: 1;
}

@media (hover: hover) {
  .css-1l86mjk:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    color: var(--theme-ui-colors-black-100, #000000);
  }
}

.css-1t7h2t3 {
  color: var(--theme-ui-colors-black-100, #000000);
  min-width: 40px;
}

.css-13o7eu2 {
  display: block;
}

.css-7rqqtz {
  color: var(--theme-ui-colors-black-100, #000000);
}

.css-o5ua0 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-ui-colors-black-100, #000000);
}

.css-zdpt2t {
  margin-left: auto;
}

.css-5i9hgn {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: auto;
}

.css-lnyvlk {
  z-index: 2;
}

.css-uopikp {
  background-color: var(--theme-ui-colors-white-100, #ffffff);
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform, boxShadow;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--theme-ui-colors-black-30, #b3b3b3);
  cursor: pointer;
  z-index: 2;
}

@media (hover: hover) {
  .css-uopikp:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    color: var(--theme-ui-colors-black-100, #000000);
  }
}

.css-o4yxxs {
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  margin-left: auto;
  width: 100%;
  z-index: 3;
}

@media screen and (min-width: 52em) {
  .css-o4yxxs {
    padding-top: 48px;
    max-width: 620px;
  }
}

.css-kdgr71 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  margin-left: auto;
  width: 100%;
  z-index: 3;
}

@media screen and (min-width: 52em) {
  .css-kdgr71 {
    padding-top: 28px;
    max-width: 620px;
  }
}

.css-1towv9s {
  position: relative;
  z-index: 5;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.css-ieqkqz {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 32px;
  position: relative;
  z-index: 5;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.css-7e01kc {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 3px;
}

.css-pbfz6o {
  border-radius: 10px;
  background-color: var(--theme-ui-colors-black-5, #f2f2f2);
}

.css-1sadlg5 {
  height: 164px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0;
  margin-left: 0;
  border-radius: 10px;
  background-color: var(--theme-ui-colors-black-5, #f2f2f2);
}

.css-s5ng7h {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: var(--theme-ui-colors-black-10, #e6e6e6);
  height: 164px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0;
  margin-left: 0;
  border-radius: 10px;
  background-color: var(--theme-ui-colors-black-5, #f2f2f2);
}

.css-1glq2si {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 24px;
}

.css-17znm6m {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-family: heading;
  font-weight: 600;
  line-height: heading;
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 24px;
  font-weight: 600;
}

.css-yxom3w {
  gap: 10px;
}

.css-1zj86l {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 3px;
  gap: 10px;
}

.css-1qir2a6 {
  height: 84px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0;
  margin-left: 0;
  border-radius: 10px;
  background-color: var(--theme-ui-colors-black-5, #f2f2f2);
}

.css-22scgt {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: var(--theme-ui-colors-black-10, #e6e6e6);
  height: 84px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0;
  margin-left: 0;
  border-radius: 10px;
  background-color: var(--theme-ui-colors-black-5, #f2f2f2);
}

.css-1939klg {
  padding-top: 48px;
  padding-bottom: 48px;
}

.css-1tcix3q {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  padding-top: 48px;
  padding-bottom: 48px;
}

.css-1igkdxz {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.css-vjofe8 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.css-1rp1tlz {
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 52em) {
  .css-1rp1tlz {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.css-4hpct8 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 52em) {
  .css-4hpct8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.css-1odgeym {
  grid-column: 1/7;
}

.css-1v50i69 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  grid-column: 1/7;
}

.css-4cffwv {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.css-1nrzg57 {
  color: var(--theme-ui-colors-black-10, #e6e6e6);
  display: block;
}

.css-1s063fg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: var(--theme-ui-colors-black-10, #e6e6e6);
  display: block;
}

.css-13o7eu2 {
  display: block;
}

.css-1b0s1wg {
  padding-bottom: 33.6734694%;
  height: 0;
  width: 65px;
}

.css-8sqqxm {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  padding-bottom: 33.6734694%;
  height: 0;
  width: 65px;
}

.css-14250g4 {
  grid-column: 1/3;
}

.css-jzxtry {
  gap: 5px;
  display: grid;
  grid-column: 1/3;
}

@media screen and (min-width: 52em) {
  .css-jzxtry {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.css-19a0g5n {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 3px;
  gap: 5px;
  display: grid;
  grid-column: 1/3;
}

@media screen and (min-width: 52em) {
  .css-19a0g5n {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.css-sasf3g {
  display: block;
  font-size: 14px;
  color: var(--theme-ui-colors-black-30, #b3b3b3);
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 600;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 8px;
  -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

@media screen and (min-width: 52em) {
  .css-sasf3g {
    margin-right: 16px;
  }
}

.css-sasf3g:last-of-type {
  margin-right: 0;
}

@media (hover: hover) {
  .css-sasf3g:hover {
    color: var(--theme-ui-colors-black-60, #666666);
  }
}

.css-16cfqyk {
  grid-column: 3/7;
}

@media screen and (min-width: 52em) {
  .css-16cfqyk {
    margin-left: auto;
  }
}

.css-tbf4t3 {
  gap: 5px;
  display: grid;
  grid-column: 3/7;
}

@media screen and (min-width: 52em) {
  .css-tbf4t3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (min-width: 52em) {
  .css-tbf4t3 {
    margin-left: auto;
  }
}

.css-52avou {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 3px;
  gap: 5px;
  display: grid;
  grid-column: 3/7;
}

@media screen and (min-width: 52em) {
  .css-52avou {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (min-width: 52em) {
  .css-52avou {
    margin-left: auto;
  }
}

.css-5uuy7 {
  box-sizing: border-box;
  margin: 0px 0px 0px 8px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  color: var(--theme-ui-colors-black-100, #000000);
  text-decoration: none;
  position: relative;
  top: -2px;
  font-size: 14px;
}

.css-wbxa2r {
  box-sizing: border-box;
  margin: 0px 0px 5px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
}

.css-rivphl {
  box-sizing: border-box;
  margin: 0px 0px 10px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 24px;
}

.css-unu6y4 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  font-weight: 600;
  color: #666666;
}

.css-oiuqw7 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(1, 1fr);
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
}

.css-3rf65c {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 32px;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: #e6e6e6;
}

.css-6iow5d {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  text-decoration: none;
  font-size: inherit;
  will-change: transform;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  padding: 16px 32px;
  border-radius: 15px;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  cursor: pointer;
  outline: none;
  background-color: var(--theme-ui-colors-black-100, #000000);
  color: var(--theme-ui-colors-white-100, #ffffff);
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  border-color: var(--theme-ui-colors-black-90, #1a1a1a);
  min-height: 60px;
  width: 100%;
}

.css-6vfr7z {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 32px;
  box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  position: relative;
}

.css-zrdji6 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  padding: 20px;
  border-radius: 12px;
  align-items: flex-start;
  background-color: var(--theme-ui-colors-white-100, #ffffff);
  position: relative;
}

.css-zrdji6::before {
  content: "";
  box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px;
  border-radius: 12px;
  position: absolute;
  inset: 0px;
  z-index: -2;
}

.css-942keq {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  flex: 1 1 0%;
  flex-direction: column;
  gap: 5px;
}

.css-1ibxqar {
  overflow: hidden;
  color: var(--theme-ui-colors-black-30, #b3b3b3);
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  will-change: transform;
  position: absolute;
  right: 20px;
  bottom: 20px;
  margin-left: 0px;
}

.css-w6k6xy {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  order: 2;
}
.css-5avtf0 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  order: 1;
}

.css-68da1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: inherit;
}

.css-12hibeq {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: inline-block;
}

.css-i44hey {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 16px;
  white-space: pre;
}
.css-rhj7lr {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  color: var(--theme-ui-colors-black-60, #666666);
  font-size: 16px;
}

.css-1lrq472 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  text-align: right;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1 1 0%;
}

.css-1btdx2y {
  text-decoration: none;
  display: inline;
  font-size: 14px;
  margin-left: 3px;
}

.css-1bmct4e {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: FormularMono, Consolas, "Andale Mono WT", "Andale Mono",
    "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono",
    "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco,
    "Courier New", Courier, monospace;
  font-weight: 400;
  color: var(--theme-ui-colors-black-60, #666666);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  cursor: pointer;
  display: inline;
  font-size: 14px;
}

.css-1sdwxkz {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  color: var(--theme-ui-colors-black-60,#666666);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  cursor: pointer;
  display: inline;
  font-size: 14px;
}

.st--c-lkoxAx {
  background: var(--st--colors-white100);
  color: var(--st--colors-black100);
  font-family: var(--st--fonts-body);
  box-shadow: var(--st--shadows-0);
  border-radius: var(--st--radii-3);
  padding-top: var(--st--space-1);
  padding-bottom: var(--st--space-2);
  padding-left: var(--st--space-3);
  padding-right: var(--st--space-3);
  margin-top: var(--st--space-1);
  margin-bottom: var(--st--space-1);
  margin-left: var(--st--space-1);
  margin-right: var(--st--space-1);
  font-weight: 600;
  max-width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (min-width: 40em) {
  .css-zrdji6 {
    padding-left: 24px;
    padding-right: 24px;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-942keq {
    display: flex;
    flex-direction: row;
    gap: 0px;
  }
  .css-1ibxqar {
    position: relative;
    right: 0px;
    bottom: 0px;
    margin-left: 24px;
  }
  .css-w6k6xy {
    flex-wrap: wrap;
    order: 1;
    margin-right: 24px;
  }
  .css-5avtf0 {
    order: 2;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-i44hey {
    font-size: 18px;
    margin-bottom: 3px;
  }
  .css-rhj7lr {
    font-size: 16px;
  }
  .css-1lrq472 {
    flex-direction: column;
  }
  .css-1btdx2y {
    font-size: 16px;
  }
  .css-1bmct4e, .css-1sdwxkz {
    font-size: 16px;
  }
}
