.container {
  width: 100%;
  padding: 0 25px;
  max-width: 1600px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 24px;
  }
  .header .container {
    padding: 0;
  }
}
